document.addEventListener("DOMContentLoaded", () => {
  const languageBtns = document.querySelectorAll('.languageDropdown button');
  if (!languageBtns) return;
  languageBtns.forEach(languageBtn => languageBtn.addEventListener('click', () => {
    const currentUrl = window.location.href; // Hent den aktuelle URL
    const langCode = languageBtn.dataset.langCode; // Hent den nye sprogkode

    // Opdel URL'en i dele baseret på '/'
    const urlParts = currentUrl.split('/');

    // Sprogkoden er altid på samme sted (f.eks. tredje segment efter domænet)
    // Ændr den aktuelle sprogkode til den nye
    urlParts[3] = langCode;
    window.location.href = urlParts.join('/');
  }));
});